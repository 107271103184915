import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Security from 'src/pages/Security';
import SecurityDetails from 'src/pages/SecurityDetails';
import * as T from './../types';
import { ParentContainer } from './styles';
import useFeatureFlagsState from 'src/hooks/useFeatureFlagsState';

const MainComponent: React.FC<T.AppPropsType> = () => {
  // useFeatureFlagsState hook retrieves and manages the state of the security feature flags
  const { flags, flagsLoadingState } = useFeatureFlagsState();

  return (
    <ParentContainer>
      <BrowserRouter>
        <Routes>
          <Route
            path="/:country/:language"
            element={
              <Security
                flags={flags}
                flagsLoadingState={flagsLoadingState}
              />
            }
          />
          <Route
            path="/:country/:language/security/:type"
            element={
              <SecurityDetails
                flags={flags}
                flagsLoadingState={flagsLoadingState}
              />
            }
          />
          <Route
            path="*"
            element={
              <Security
                flags={flags}
                flagsLoadingState={flagsLoadingState}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ParentContainer>
  );
};

export default MainComponent;
