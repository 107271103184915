import { Button } from '@veneer/core';
import React, { useEffect } from 'react';
import { publishEvent } from 'src/utils/analytics';
import {
  ErrorContent,
  ErrorTextContainer,
  ErrorTopWrapper,
  StyledIconTools
} from './styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

const ErrorBoundary = ({ analyticsEvent }: { analyticsEvent: any }) => {
  const { translate } = useDependencyManagerContext();

  useEffect(() => {
    publishEvent(analyticsEvent);
  }, [analyticsEvent]);

  return (
    <ErrorContent
      data-testid="error-boundary"
      aria-label="ErrorBoundary"
    >
      <ErrorTopWrapper>
        <StyledIconTools />
        <ErrorTextContainer>
          <div className="subtitle-large">
            {`${translate('common.itsNotYou', 'It’s not you, it’s us…')}`}
          </div>
          <div className="body">
            {`${translate(
              'common.thereWasAnInterruption',
              'There was an interruption while loading this information.'
            )}`}
          </div>
        </ErrorTextContainer>
      </ErrorTopWrapper>
      <Button
        appearance="secondary"
        onClick={() => {
          window.location.reload();
        }}
        data-testid="try-again-button"
      >
        {`${translate('common.tryAgain', 'Please try again')}`}
      </Button>
    </ErrorContent>
  );
};

export default ErrorBoundary;
