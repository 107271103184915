import { useEffect, useState } from 'react';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlag, FeatureFlagLoadingState } from 'src/types/FeatureFlag';
import { FeatureFlagsEnum } from 'src/utils/enums';

const useFeatureFlagsState = (): {
  flags: FeatureFlag;
  flagsLoadingState: FeatureFlagLoadingState;
} => {
  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);

  const {
    featureFlagValue: securityListLoginDetails,
    isLoading: isSecurityListLoginDetailsLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityListLoginDetails);

  const {
    featureFlagValue: securityHpIdEmail,
    isLoading: isSecurityHpIdEmailLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityHPIDEmail);

  const {
    featureFlagValue: securityHpIdPhone,
    isLoading: isSecurityHpIdPhoneLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityHPIDPhone);

  const {
    featureFlagValue: securityHpIdPassword,
    isLoading: isSecurityHpIdPasswordLoading
  } = useFeatureFlags(FeatureFlagsEnum.securityHPIDPassword);

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagsEnum.originalFeedbackExperience);

  // State to hold the feature flags
  const [flags, setFlags] = useState<FeatureFlag>({
    isQualtricsSurveyEnabled: false,
    isSecurityListLoginDetailsEnabled: false,
    isSecurityHpIdEmailEnabled: false,
    isSecurityHpIdPhoneEnabled: false,
    isSecurityHpIdPasswordEnabled: false,
    isOriginalFeedbackExperienceEnabled: false
  });

  // State to hold the loading status of each flag
  const [flagsLoadingState, setFlagsLoadingState] =
    useState<FeatureFlagLoadingState>({
      isQualtricsSurveyLoading: true,
      isSecurityListLoginDetailsLoading: true,
      isSecurityHpIdEmailLoading: true,
      isSecurityHpIdPhoneLoading: true,
      isSecurityHpIdPasswordLoading: true,
      isOriginalFeedbackExperienceLoading: true
    });

  // Effect to update the flags and loading state when feature flags change
  useEffect(() => {
    setFlags({
      isQualtricsSurveyEnabled: qualtricsSurvey,
      isSecurityListLoginDetailsEnabled: securityListLoginDetails,
      isSecurityHpIdEmailEnabled: securityHpIdEmail,
      isSecurityHpIdPhoneEnabled: securityHpIdPhone,
      isSecurityHpIdPasswordEnabled: securityHpIdPassword,
      isOriginalFeedbackExperienceEnabled: originalFeedbackExperience
    });

    setFlagsLoadingState({
      isQualtricsSurveyLoading: isQualtricsSurveyLoading,
      isSecurityListLoginDetailsLoading: isSecurityListLoginDetailsLoading,
      isSecurityHpIdEmailLoading: isSecurityHpIdEmailLoading,
      isSecurityHpIdPhoneLoading: isSecurityHpIdPhoneLoading,
      isSecurityHpIdPasswordLoading: isSecurityHpIdPasswordLoading,
      isOriginalFeedbackExperienceLoading: isOriginalFeedbackExperienceLoading
    });
  }, [
    qualtricsSurvey,
    securityListLoginDetails,
    securityHpIdEmail,
    securityHpIdPhone,
    securityHpIdPassword,
    isQualtricsSurveyLoading,
    isSecurityListLoginDetailsLoading,
    isSecurityHpIdEmailLoading,
    isSecurityHpIdPhoneLoading,
    isSecurityHpIdPasswordLoading,
    originalFeedbackExperience,
    isOriginalFeedbackExperienceLoading
  ]);

  return { flags, flagsLoadingState };
};

export default useFeatureFlagsState;
