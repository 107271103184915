import { useQuery } from '@clientos/graphql-client/dist/react';
import {
  Card,
  formatPhoneNumber,
  Loading,
  ROW_TYPE,
  useReadOnlyDataStreamer
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { ColumnProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardColumns';
import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_USER_DETAILS from 'src/graphql/getUserDetails';
import { Label, Row, Value, ValueContent } from 'src/styles/styles';
import {
  LoginDetailsError,
  ManageEmailHyperLinkClicked,
  ManagePhoneHyperLinkClicked,
  ChangePasswordHyperLinkClicked,
  ManageLoginDetailsHyperLinkClicked,
  ChangePasswordControlButtonClicked,
  ManageEmailControlButtonClicked,
  ManagePhoneControlButtonClicked
} from 'src/utils/analytics';
import { FormTypesEnum } from 'src/utils/enums';
import { URLS } from 'src/utils/urls';
import Verified from '../Verified';
import { FeatureFlag, FeatureFlagProps } from 'src/types/FeatureFlag';
import { RowContent } from './styles';
import User from 'src/types/User';
import getFieldsToFetch from 'src/utils/getFieldsToFetch';

interface ValueColumnProps {
  value: string;
  verified?: boolean | null;
  dataComponentName?: string;
}

interface RowDataProps {
  label: string;
  column: ValueColumnProps;
  dataComponentName?: string;
}

interface LoginDetailsSectionProps {
  userData: User;
  flags: FeatureFlag;
}

export interface LoginDetailsType {
  email: string;
  emailVerified: boolean | null;
  phone: string;
  phoneVerified: boolean | null;
}

const ValueColumn = ({
  value,
  verified,
  dataComponentName
}: ValueColumnProps) => {
  return (
    <Value>
      <ValueContent
        {...(dataComponentName ? { 'data-component': dataComponentName } : {})}
      >
        {value}
      </ValueContent>
      {verified != null && <Verified verified={verified} />}
    </Value>
  );
};

const RowData = ({ label, column, dataComponentName }: RowDataProps) => {
  return (
    <Row>
      <Label className="caption">{label}</Label>
      <ValueColumn
        {...(dataComponentName ? { dataComponentName: dataComponentName } : {})}
        value={column.value}
        verified={column.verified}
      />
    </Row>
  );
};

const LoadingSection = () => {
  return (
    <Card
      rowsColorChangeEnabled={false}
      childrenStyle={{ align: 'center', marginTop: '16px' }}
    >
      <Loading testid="loading-section" />
    </Card>
  );
};

const LoginDetails = ({
  flags,
  flagsLoadingState
}: FeatureFlagProps): React.JSX.Element => {
  const { loading, error, data } = useQuery(GET_USER_DETAILS);
  const userData = {
    data: data,
    error: error
  };

  const loadingFlags = flagsLoadingState.isSecurityListLoginDetailsLoading;

  if (loading || loadingFlags) {
    return <LoadingSection />;
  }

  return (
    <LoginDetailsSection
      flags={flags}
      userData={userData}
    />
  );
};

const LoginDetailsSection = ({
  flags,
  userData
}: LoginDetailsSectionProps): React.JSX.Element => {
  const { translate } = useDependencyManagerContext();
  const [loadingHPIDData, setLoadingHPIDData] = useState(true);
  const [loginDetails, setLoginDetails] = useState<LoginDetailsType>({
    email: '',
    emailVerified: null,
    phone: '',
    phoneVerified: null
  });

  useReadOnlyDataStreamer(setLoginDetails, getFieldsToFetch);

  const { email, emailVerified, phone, phoneVerified } =
    userData?.data?.account?.user || {};

  //Static strings
  const fsDataComponentName = 'UserDataField';
  const loginDetailsTitle = translate('security.loginDetails', 'Login details');
  const emailLabel = translate('profile.email', 'Email');
  const emptyEmail = translate('profile.noEmail', 'No email');
  const phoneLabel = translate('profile.phone', 'Phone');
  const emptyPhone = translate('profile.noPhone', 'No phone');
  const passwordMask = '*************';
  const passwordLabel = translate(
    'security.currentPassword',
    'Current password'
  );

  useEffect(() => {
    const allDetailsLoaded = Object.values(loginDetails).every(
      (detail) => detail !== ''
    );
    allDetailsLoaded && setLoadingHPIDData(false);
  }, [loginDetails]);

  const showSecurityListLoginDetails = flags.isSecurityListLoginDetailsEnabled;

  // We need to return null in case of empty phone number, due to verified prop in ValueColumn component
  const handleHPIDPhone = (phone: string): string => {
    const currentValue = formatPhoneNumber(phone, emptyPhone);
    return currentValue !== emptyPhone ? currentValue : null;
  };

  function OldLoginDetails() {
    const title = {
      content: loginDetailsTitle,
      type: ROW_TYPE.EXTERNAL,
      link: {
        url: URLS.HpIdSecurity,
        dataTestId: 'ManageLoginDetailsLink'
      },
      analyticsEvent: ManageLoginDetailsHyperLinkClicked
    };

    const content = (
      <RowContent>
        <RowData
          dataComponentName={fsDataComponentName}
          label={emailLabel}
          column={{
            value: email || emptyEmail,
            verified: email ? emailVerified : null
          }}
        />
        <RowData
          dataComponentName={fsDataComponentName}
          label={phoneLabel}
          column={{
            value: phone || emptyPhone,
            verified: phone ? phoneVerified : null
          }}
        />
        <RowData
          label={passwordLabel}
          column={{
            value: passwordMask
          }}
          data-testid="default-password-row"
        />
      </RowContent>
    );

    return (
      <Card
        title={title}
        rowsColorChangeEnabled={false}
        childrenStyle={{ align: 'left', marginTop: '8px' }}
      >
        {userData?.data?.account?.user && content}
      </Card>
    );
  }

  function NewLoginDetails() {
    const emailColumns: ColumnProps[] = [];
    const phoneColumns: ColumnProps[] = [];
    const loginDetailsData = {
      email: loginDetails.email,
      emailVerified:
        String(loginDetails.emailVerified).toLowerCase() === 'true',
      phone: handleHPIDPhone(loginDetails.phone),
      phoneVerified: String(loginDetails.phoneVerified).toLowerCase() === 'true'
    };

    const getLinkType = (flag: boolean): ROW_TYPE => {
      return flag ? ROW_TYPE.INTERNAL : ROW_TYPE.EXTERNAL;
    };

    emailColumns.push({ value: emailLabel });
    emailColumns.push({
      value: loginDetailsData.email ? (
        <ValueColumn
          value={loginDetailsData.email}
          verified={loginDetailsData.emailVerified}
          dataComponentName={fsDataComponentName}
        />
      ) : (
        emptyEmail
      )
    });

    phoneColumns.push({ value: phoneLabel });
    phoneColumns.push({
      value: loginDetailsData.phone ? (
        <ValueColumn
          value={loginDetailsData.phone}
          verified={loginDetailsData.phoneVerified}
          dataComponentName={fsDataComponentName}
        />
      ) : (
        emptyPhone
      )
    });

    const rows = [
      {
        index: 0,
        content: emailColumns,
        type: getLinkType(flags.isSecurityHpIdEmailEnabled),
        link: {
          url: URLS.HpIdSecurity,
          pathname: `/security/${FormTypesEnum.Email}`,
          dataTestId: 'ManageEmailLink'
        },
        analyticsEvent: flags.isSecurityHpIdEmailEnabled
          ? ManageEmailControlButtonClicked
          : ManageEmailHyperLinkClicked
      },
      {
        index: 1,
        content: phoneColumns,
        type: getLinkType(flags.isSecurityHpIdPhoneEnabled),
        link: {
          url: URLS.HpIdSecurity,
          pathname: `/security/${FormTypesEnum.Phone}`,
          dataTestId: 'ManagePhoneLink'
        },
        analyticsEvent: flags.isSecurityHpIdPhoneEnabled
          ? ManagePhoneControlButtonClicked
          : ManagePhoneHyperLinkClicked
      },
      {
        index: 2,
        content: [{ value: passwordLabel }, { value: passwordMask }],
        type: getLinkType(flags.isSecurityHpIdPasswordEnabled),
        link: {
          url: URLS.HpIdSecurity,
          pathname: `/security/${FormTypesEnum.Password}`,
          dataTestId: 'ChangePasswordLink'
        },
        analyticsEvent: flags.isSecurityHpIdPasswordEnabled
          ? ChangePasswordControlButtonClicked
          : ChangePasswordHyperLinkClicked
      }
    ] as CardRowProps[];

    return (
      <Card
        title={{ content: loginDetailsTitle }}
        rows={rows}
      />
    );
  }

  if (userData?.error && !showSecurityListLoginDetails) {
    return (
      <Card
        title={{
          content: loginDetailsTitle
        }}
        rowsColorChangeEnabled={false}
        childrenStyle={{ align: 'center', marginTop: '16px' }}
        data-testid="error-section"
      >
        <ErrorBoundary
          analyticsEvent={LoginDetailsError(userData?.error.message)}
        />
      </Card>
    );
  }

  if (loadingHPIDData && showSecurityListLoginDetails) {
    return <LoadingSection />;
  }

  return showSecurityListLoginDetails ? (
    <NewLoginDetails />
  ) : (
    <OldLoginDetails />
  );
};

export default LoginDetails;
